
import {defineComponent, PropType} from "vue";
import {default as Expression, ExpressionNode} from "@/components/automation/metricConditionTree/condition/compare/Expression.vue";
import Calculated from "@/components/automation/metricConditionTree/condition/compare/expression/Calculated.vue"

export type CompareNode = {
  condition: {
    expr1: ExpressionNode,
    expr2: ExpressionNode,
    operator: string
  },
  matched: boolean,
  type: string,
}

export default defineComponent({
  name: "Compare",
  props: {
    node: {
      type: Object as PropType<CompareNode>,
      required: true,
    }
  },

  components: {
    Expression,
    Calculated,
  },

  data() {
    return {
      calculatedExpressionsMeta: {
        visible: {},
      }
    };
  },

  computed: {
    matchClass(): string {
      return this.node.matched ? 'text-success' : 'text-danger'
    },

    operator(): string {
      const map = {
        'equal': '==',
        'greater_than': '>',
        'less_than': '<',
        'greater_than_or_equal': '>=',
        'less_than_or_equal': '<=',
      }
      const operator = this.node.condition.operator;
      return (operator in map) ? map[operator] : operator;
    },

    calculatedExpressions(): null|Record<string, any>
    {
      let expressions: null|Record<string, any> = null;
      Object.keys(this.node.condition)
        .filter(key => key.includes('expr'))
        .forEach(key => {
          const expression: ExpressionNode = this.node.condition[key];
          const formula = expression.metric?.formula ?? null;
          const nestedMetrics = expression.metric?.nestedMetrics ?? null;
          if(!formula && !nestedMetrics) return;

          if(!expressions) expressions = {};
          expressions[key] = expression;
        });
      return expressions
    },

    isCalculatedExpressionsVisible(): boolean {
      const visibleCalculatedExpressions = Object.values(this.calculatedExpressionsMeta.visible).filter(isVisible => isVisible);
      return (visibleCalculatedExpressions.length > 0)
    },

    calculatedExpressionsVisible(): null|Record<string, any> {
      if(!this.isCalculatedExpressionsVisible) return null;
      if(!this.calculatedExpressions) return null;

      let expressions = {};
      Object.keys(this.calculatedExpressionsMeta.visible).forEach(key => {
        const isVisible = this.calculatedExpressionsMeta.visible[key];
        if(!isVisible) return;
        const expression: ExpressionNode = (this.calculatedExpressions && key in this.calculatedExpressions) ? this.calculatedExpressions[key] : null;
        if(!expression) return;
        expressions[key] = expression;
      });
      return expressions;
    },
  },

  methods: {
    isCalculatedExpression(expressionKey): boolean {
      if(!this.calculatedExpressions) return false;
      return (expressionKey in this.calculatedExpressions);
    },
    onExpressionLabelClick(expressionKey) {
      // toggle calculated expression
      if(this.isCalculatedExpression(expressionKey)) {
        const isVisible = (expressionKey in this.calculatedExpressionsMeta.visible) ? this.calculatedExpressionsMeta.visible[expressionKey] : false;
        this.calculatedExpressionsMeta.visible[expressionKey] = !isVisible;
      }
    }
  }
});
