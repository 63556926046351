<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer"
       :class="[loading && 'overlay overlay-block']"
  >
    <div v-if="loading" class="overlay-layer card-rounded bg-dark bg-opacity-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
             id="kt_customers_table"
             role="grid">
        <!--begin::Table head-->
        <thead>
          <!--begin::Table row-->
          <tr
            class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
            role="row"
          >
            <template v-for="(cell, i) in tableHeader" :key="i">
              <th @click="updateSorting(cell.sortingField ? cell.sortingField : cell.key, cell.sortable)"
                  :class="[
                    cell.name && 'min-w-125px',
                    cell.sortable !== false && 'sorting',
                    // tableHeader.length - 1 === i && 'text-end',
                    (sort.columnName === `${cell.sortingField ? cell.sortingField : cell.key}` && sort.order === 'desc') &&
                      'sorting_desc',
                    (sort.columnName === `${cell.sortingField ? cell.sortingField : cell.key}` && sort.order === 'asc') &&
                      'sorting_asc',
                    ...cell.class || [],
                  ]"
                  tabindex="0"
                  rowspan="1"
                  colspan="1"
                  style="cursor: pointer">
                {{ cell.name }}
              </th>
            </template>
          </tr>
          <!--end::Table row-->
        </thead>
        <!--end::Table head-->
        <!--begin::Table body-->
        <tbody class="fw-bold text-gray-900">
          <template v-if="getItems.length">
            <template v-for="(item, i) in getItems" :key="i">
              <tr class="odd" :class="rowClass" @click="onRowClick(item)">
                <template v-for="(cell, i) in tableHeader" :key="i">
                  <td>
                    <slot :name="`cell-${cell.columnKey || cell.key}`" :row="item" :cell="cell">
                      {{ item[cell.key] }}
                    </slot>
                  </td>
                </template>
                <!--end::Item=-->
              </tr>
            </template>
          </template>
          <template>
            <tr class="odd">
              <td colspan="7" class="dataTables_empty">
                {{ emptyTableText }}
              </td>
            </tr>
          </template>
        </tbody>
        <!--end::Table body-->
      </table>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
        <div v-if="enableItemsPerPageDropdown"
             class="dataTables_length">
          <label>
            <select class="form-select form-select-sm form-select-solid"
                      @change="setItemsPerPage">
              <option v-for="optionValue in rowsPerPageOptions"
                      :value="optionValue" :selected="rowsPerPage === optionValue"
                      :key="`datagrid-items-per-page-${optionValue}`">
                {{ optionValue }}
              </option>

            </select>
          </label>
        </div>
      </div>
      <div v-if="enablePagination"
           class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <el-pagination v-model:current-page="pagination.page"
                       @current-change="currentPageChange"
                       :page-size="pagination.rowsPerPage"
                       layout="prev, pager, next"
                       :total="pagination.total"
                       :hide-on-single-page="true"
                       background>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  toRef,
  onMounted,
  watch,
  getCurrentInstance,
  reactive,
  Ref,
} from "vue";
import arraySort from 'array-sort';

interface IPagination {
  page: Ref<number>;
  total: Ref<number>;
  rowsPerPage: Ref<number>;
}

interface ISorting {
  columnName: Ref<string>;
  order: Ref<string>;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  columnKey?: string;
  sortingField?: string;
  sortable?: boolean;
  class?: string[];
  metadata?: null|Record<any, any>;
}

const rowsPerPageOptions = [
  10, 25, 50, 100
]

export default defineComponent({
  name: "kt-datatable",
  emit: ["current-change", "sort", "items-per-page-change", "row-click"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    enablePagination: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    sortOrder: { type: String, default: "asc" },
    sortColumnName: { type: String, default: "" },
    rowClass: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    // const currentSort = ref<string>("");
    const sortOrder = toRef(props, 'sortOrder');
    const sortColumnName = toRef(props, 'sortColumnName');
    const tableData = toRef(props, 'tableData');
    const currentPage = toRef(props, 'currentPage');
    const total = toRef(props, 'total');
    const rowsPerPage = toRef(props, 'rowsPerPage');

    const pagination = reactive<IPagination>({
      page: ref<number>(currentPage.value),
      total: ref<number>(total.value),
      rowsPerPage: ref<number>(rowsPerPage.value),
    });
    const sort = reactive<ISorting>({
      columnName: ref(sortColumnName.value),
      order: ref(sortOrder.value),
    });

    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    /*
     * Watchers
     */
    watch(tableData, () => {
      if (!("onCurrentChange" in vnodeProps)) {
        pagination.total = tableData.value.length;
      }
    });

    watch(total, () => {
      pagination.total = total.value;
    })

    watch(rowsPerPage, () => {
      pagination.rowsPerPage = rowsPerPage.value;
    })

    watch(sortColumnName, () => {
      sort.columnName = sortColumnName.value;
    })

    watch(sortOrder, () => {
      sort.order = sortOrder.value;
    })

    onMounted(() => {
      sort.columnName = props.sortColumnName
      sort.order = props.sortOrder;
      pagination.page = props.currentPage ? props.currentPage : 1;
      pagination.total = props.total ? props.total : tableData.value.length;
      pagination.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return tableData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(tableData.value));
        const startFrom = pagination.page * pagination.rowsPerPage - pagination.rowsPerPage;
        return clone.splice(startFrom, pagination.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.page = val;
      }
    };

    const updateSorting = (columnName, sortable) => {
      if (sortable === false) return;


      // change sort order only if we updating re-sorting same column
      if(sort.columnName === columnName) {
        sort.order = (sort.order === "asc" ) ? "desc" : "asc";
      }
      sort.columnName = columnName;
      if ("onSort" in vnodeProps) {
        emit("sort", { ...sort });
      } else {
        arraySort(tableData.value, columnName, { reverse: (sort.order === "asc") });
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.rowsPerPage = parseInt(event.target.value);
      }
    };

    const onRowClick = (row) => {
        if ("onRowClick" in vnodeProps) {
            emit("row-click", row);
        }
    }

    return {
      pagination,
      currentPageChange,
      getItems,
      sort,
      updateSorting,
      setItemsPerPage,
      onRowClick,
      rowsPerPageOptions,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}

table.dataTable td.dataTables_empty,
table.dataTable th.dataTables_empty {
  text-align: center;
}

div.dataTables_wrapper div.dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
