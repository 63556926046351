
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import Combine from "@/components/automation/metricConditionTree/condition/Combine.vue";
import MetricAction from "@/views/automation/actionLog/modal/metrics/Action.vue";

export default defineComponent({
  name: "Metrics",

  components: {
    Combine,
    MetricAction,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    executionResult: {
      type: Object,
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      // TODO: update after AuthModule is refactored
      userSettings: 'getUserSettings',
    }),

    canAccessMetricsDebugView(): boolean
    {
      return this?.userSettings?.access_metrics_debug_view ?? false;
    },

    metricConditionsData() {
      return this?.executionResult?.metric_conditions_data ?? null;
    },

    metricsData(): any[] {
      const metricsData = this?.executionResult?.metrics_data;
      if (!metricsData) return [];

      const ruleMetricsRenderConfig = this.executionResult?.execution?.rule?.settings?.render?.metrics;

      let result: any[] = Object.keys(metricsData).map((key) => {
        let metric = metricsData[key];

        if(!metric || typeof metric !== 'object') {
          return null;
        }

        // skip metric if render config is set and does not include it
        if(
          ruleMetricsRenderConfig &&
          Array.isArray(ruleMetricsRenderConfig) &&
          ruleMetricsRenderConfig.length > 0 &&
          !ruleMetricsRenderConfig.includes(metric.name)
        ) {
          return null;
        }

        let valuePretty: null|string|number = null;
        let valueClass: null|string = null;

        if (metric.value === null) {
          valuePretty = 'N/A';
          valueClass = 'bg-light-light';
        } else if (metric.value === true) {
          valuePretty = 'Yes';
          valueClass = 'bg-light-success';
        } else if (metric.value === false) {
          valuePretty = 'No';
          valueClass = 'bg-light-danger';
        }

        return {
          label: this.$t(metric.name),
          value: metric.value,
          valuePretty,
          valueClass
        }
      }).filter((item) => item != null);

      result = result.sort((a, b) => {
        return (a.label < b.label ? -1 : (a.label > b.label ? 1 : 0));
      });

      return result;
    },

    actions() {
      return this.executionResult?.actions;
    },

    tacticName() {
      return this.executionResult?.execution?.rule?.tactic?.name;
    },

    ruleName() {
      return this.executionResult?.execution?.rule?.name;
    },

    ruleTasks(): any[]|null {
      return this.executionResult?.execution?.rule?.tasks ?? null;
    },

    objectType() {
      // return this.action?.object_type ? this.$t(this.action?.object_type) : '';
      return this.executionResult?.object_type_label || '';
    },

    objectId() {
      return this.executionResult?.object_id;
    },

    objectName() {
      return this.executionResult?.object?.name;
    },
  },

  methods: {
    onChange(value) {
      this.$emit('update:modelValue', value);
    },
  }
})
