
import {defineComponent} from "vue";

export default defineComponent({
  name: "RuleName",

  props: {
    row: {
      type: Object,
    }
  },

  computed: {
    ruleName() {
      return this.row?.execution?.rule?.name;
    }
  }

});
