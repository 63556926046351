
import {defineComponent} from "vue";
import {mapActions, mapMutations, mapGetters} from "vuex";
import FilterCreatedAtRange from './filters/CreatedAtRange.vue';
import FilterSearch from './filters/Search.vue';
import FilterTacticSelect from './filters/TacticSelect.vue';
import FilterRuleSelect from './filters/RuleSelect.vue';
import FilterObjectTypeSelect from './filters/ObjectTypeSelect.vue';
import FilterActionSelect from './filters/ActionSelect.vue';
import NonExecutableToggle from './filters/NonExecutableToggle.vue';

export default defineComponent({
  name: "Filters",

  components: {
    FilterCreatedAtRange,
    FilterSearch,
    FilterTacticSelect,
    FilterRuleSelect,
    FilterObjectTypeSelect,
    FilterActionSelect,
    NonExecutableToggle
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchList: 'automation/ruleExecutionResults/fetch',
    }),
    ...mapMutations({
      resetListFilters: 'automation/ruleExecutionResults/resetListFilters'
    })
  }
});
