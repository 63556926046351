<template>
  <div>
    <i v-if="ruleTask" :class="['fa', 'fa-genderless', 'action-indicator', 'me-1', ruleTaskMatchClass]" />
    {{ $t(action.action_label) }}<span v-if="ruleTask">: {{ ruleTaskName }}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "MetricAction",

  props: {
    action: {
      type: Object,
    },
    ruleTasks: {
      type: Object,
      required: false,
    }
  },

  computed: {

    ruleTask() {
      if(!this.ruleTasks) return null;

      const ruleTaskId = this.action?.rule_task_id ?? null;
      if(!ruleTaskId) return null;

      return this.ruleTasks.find(item => item.id === ruleTaskId);
    },

    // TODO: implement and use ruleTask.label
    ruleTaskName() {
      return this.ruleTask?.name;
    },

    ruleTaskMatchClass(): string {
      const isExecutable = this.action?.is_executable ?? false;
      return isExecutable ? 'text-success' : 'text-danger'
    },
  },

});
</script>

<style scoped>

</style>