
import {
  computed,
  defineComponent,
  ref,
  toRef,
  onMounted,
  watch,
  getCurrentInstance,
  reactive,
  Ref,
} from "vue";
import arraySort from 'array-sort';

interface IPagination {
  page: Ref<number>;
  total: Ref<number>;
  rowsPerPage: Ref<number>;
}

interface ISorting {
  columnName: Ref<string>;
  order: Ref<string>;
}

interface IHeaderConfiguration {
  name?: string;
  key: string;
  columnKey?: string;
  sortingField?: string;
  sortable?: boolean;
  class?: string[];
  metadata?: null|Record<any, any>;
}

const rowsPerPageOptions = [
  10, 25, 50, 100
]

export default defineComponent({
  name: "kt-datatable",
  emit: ["current-change", "sort", "items-per-page-change", "row-click"],
  props: {
    tableHeader: {
      type: Array as () => Array<IHeaderConfiguration>,
      required: true,
    },
    tableData: { type: Array, required: true },
    emptyTableText: { type: String, default: "No data found" },
    loading: { type: Boolean, default: false },
    currentPage: { type: Number, default: 1 },
    enableItemsPerPageDropdown: { type: Boolean, default: true },
    enablePagination: { type: Boolean, default: true },
    total: { type: Number, default: 0 },
    rowsPerPage: { type: Number, default: 10 },
    sortOrder: { type: String, default: "asc" },
    sortColumnName: { type: String, default: "" },
    rowClass: { type: String, default: "" },
  },
  components: {},
  setup(props, { emit }) {
    // const currentSort = ref<string>("");
    const sortOrder = toRef(props, 'sortOrder');
    const sortColumnName = toRef(props, 'sortColumnName');
    const tableData = toRef(props, 'tableData');
    const currentPage = toRef(props, 'currentPage');
    const total = toRef(props, 'total');
    const rowsPerPage = toRef(props, 'rowsPerPage');

    const pagination = reactive<IPagination>({
      page: ref<number>(currentPage.value),
      total: ref<number>(total.value),
      rowsPerPage: ref<number>(rowsPerPage.value),
    });
    const sort = reactive<ISorting>({
      columnName: ref(sortColumnName.value),
      order: ref(sortOrder.value),
    });

    const vnodeProps = getCurrentInstance()?.vnode.props || {};

    /*
     * Watchers
     */
    watch(tableData, () => {
      if (!("onCurrentChange" in vnodeProps)) {
        pagination.total = tableData.value.length;
      }
    });

    watch(total, () => {
      pagination.total = total.value;
    })

    watch(rowsPerPage, () => {
      pagination.rowsPerPage = rowsPerPage.value;
    })

    watch(sortColumnName, () => {
      sort.columnName = sortColumnName.value;
    })

    watch(sortOrder, () => {
      sort.order = sortOrder.value;
    })

    onMounted(() => {
      sort.columnName = props.sortColumnName
      sort.order = props.sortOrder;
      pagination.page = props.currentPage ? props.currentPage : 1;
      pagination.total = props.total ? props.total : tableData.value.length;
      pagination.rowsPerPage = props.rowsPerPage;
    });

    const getItems = computed(() => {
      if ("onCurrentChange" in vnodeProps) {
        return tableData.value;
      } else {
        const clone = JSON.parse(JSON.stringify(tableData.value));
        const startFrom = pagination.page * pagination.rowsPerPage - pagination.rowsPerPage;
        return clone.splice(startFrom, pagination.rowsPerPage);
      }
    });

    const currentPageChange = (val) => {
      if ("onCurrentChange" in vnodeProps) {
        emit("current-change", val);
      } else {
        pagination.page = val;
      }
    };

    const updateSorting = (columnName, sortable) => {
      if (sortable === false) return;


      // change sort order only if we updating re-sorting same column
      if(sort.columnName === columnName) {
        sort.order = (sort.order === "asc" ) ? "desc" : "asc";
      }
      sort.columnName = columnName;
      if ("onSort" in vnodeProps) {
        emit("sort", { ...sort });
      } else {
        arraySort(tableData.value, columnName, { reverse: (sort.order === "asc") });
      }
    };

    const setItemsPerPage = (event) => {
      if ("onItemsPerPageChange" in vnodeProps) {
        emit("items-per-page-change", parseInt(event.target.value));
      } else {
        pagination.rowsPerPage = parseInt(event.target.value);
      }
    };

    const onRowClick = (row) => {
        if ("onRowClick" in vnodeProps) {
            emit("row-click", row);
        }
    }

    return {
      pagination,
      currentPageChange,
      getItems,
      sort,
      updateSorting,
      setItemsPerPage,
      onRowClick,
      rowsPerPageOptions,
    };
  },
});
