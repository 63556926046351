<template>
  <div class="w-100">
    <div class="row pt-3 pr-4 pl-4">
      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Time period
          <filter-created-at-range />
        </label>
      </div>
      <div v-if="false" class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Tactic
          <filter-tactic-select />
        </label>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Rule
          <filter-rule-select />
        </label>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Object Type
          <filter-object-type-select />
        </label>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Action
          <filter-action-select />
        </label>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <label class="fs-6 fw-bold mb-2">
          Search
          <filter-search />
        </label>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-6 col-xl-2">
        <div class="mt-6 text-nowrap action-buttons">
          <b-button  @click="resetListFilters" variant="secondary" class="me-3">Reset</b-button>
          <b-button  @click="fetchList" variant="primary">Filter</b-button>
        </div>
        <div class="mt-2 text-nowrap">
          <label class="fs-8 text-gray-500 mb-2">
            <non-executable-toggle />
            show unperformed actions
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapMutations, mapGetters} from "vuex";
import FilterCreatedAtRange from './filters/CreatedAtRange.vue';
import FilterSearch from './filters/Search.vue';
import FilterTacticSelect from './filters/TacticSelect.vue';
import FilterRuleSelect from './filters/RuleSelect.vue';
import FilterObjectTypeSelect from './filters/ObjectTypeSelect.vue';
import FilterActionSelect from './filters/ActionSelect.vue';
import NonExecutableToggle from './filters/NonExecutableToggle.vue';

export default defineComponent({
  name: "Filters",

  components: {
    FilterCreatedAtRange,
    FilterSearch,
    FilterTacticSelect,
    FilterRuleSelect,
    FilterObjectTypeSelect,
    FilterActionSelect,
    NonExecutableToggle
  },

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
    }),
  },

  methods: {
    ...mapActions({
      fetchList: 'automation/ruleExecutionResults/fetch',
    }),
    ...mapMutations({
      resetListFilters: 'automation/ruleExecutionResults/resetListFilters'
    })
  }
});
</script>

<style scoped>

</style>