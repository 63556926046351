<template>
  <div :class="{'node': true, 'node-combine': true, 'node-collapsed' : !nestedNodesMeta.expanded}">

    <span class="node-label" @click="toggleNestedNodes">
      <i :class="['fa', 'fa-genderless', 'node-indicator', matchClass]" />
      <span class="node-rule-task ms-1" v-if="ruleTask">{{ruleTaskName}} :</span>
      <span class="node-operator ms-1 me-1">{{node.operator}}</span>
      <span class="nested-nodes-bracket nested-nodes-bracket-open">{</span>
    </span>
    <div class="node-combine-conditions" v-if="nestedNodesMeta.expanded && node.conditions">
      <template v-for="(nestedNode, index) in node.conditions" :key="index">
        <combine  v-if="nestedNode.type === 'combine'"
                  :node="nestedNode"/>
        <compare  v-if="nestedNode.type === 'compare'"
                  :node="nestedNode"/>
      </template>
    </div>
    <span v-if="!nestedNodesMeta.expanded" class="ms-1 me-1">{{node.conditions.length}}</span>
    <div class="nested-nodes-bracket nested-nodes-bracket-close">}</div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {default as Compare, CompareNode} from "@/components/automation/metricConditionTree/condition/Compare.vue";

export type CombineNode = {
  conditions: (CombineNode|CompareNode)[],
  matched: boolean,
  operator: string,
  type: string,
  rule_task_id ?: null|number,
}

export default defineComponent({
  name: "Combine",

  components: {
    Compare
  },

  props: {
    node: {
      type: Object as PropType<CombineNode>,
      required: true,
    },
    ruleTasks: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      nestedNodesMeta: {
        expanded: true,
      }
    };
  },

  computed: {
    matchClass(): string {
      return this.node.matched ? 'text-success' : 'text-danger'
    },

    ruleTask() {
      if(!this.ruleTasks) return null;

      const ruleTaskId = this.node?.rule_task_id ?? null;
      if(!ruleTaskId) return null;

      return this.ruleTasks.find(item => item.id === ruleTaskId);
    },

    // TODO: implement and use ruleTask.label
    ruleTaskName() {
      return this.ruleTask?.name;
    },

  },

  methods: {
    toggleNestedNodes(event, state:boolean|null = null) {
      if(state === null) state = !this.nestedNodesMeta.expanded;
      this.nestedNodesMeta.expanded = state;
    }
  }

});
</script>

<style scoped>

</style>