<template>
  <!--begin::Modal - View Users-->
  <b-modal v-if="executionResult"
           v-bind:modelValue="modelValue"
           v-on:update:modelValue="onChange"
           :hideFooter="true"
           size="xl">
    <template v-slot:title>
      Metrics
    </template>

    <div>
      <div>
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Tactic:&nbsp;</span>
        <span class="text-gray-600 fw-bold fs-6">{{ tacticName }}</span>
      </div>

      <div>
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Rule:&nbsp;</span>
        <span class="text-gray-600 fw-bold fs-6">{{ ruleName }}</span>
      </div>

      <div>
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Object: </span>
        <span class="text-gray-600 fw-bold fs-6">{{ $t(objectType) }} | {{ objectId }}</span>
      </div>

      <div>
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Object Name:&nbsp;</span>
        <span class="text-gray-600 fw-bold fs-6">{{ objectName ? objectName : 'N/A' }}</span>
      </div>

      <div v-if="actions" class="d-flex">
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Action(s):&nbsp;</span>
        <span class="text-gray-600 fw-bold fs-6 flex-column metricActionsContainer">
          <metric-action v-for="(action, index) in actions"
                         :key="`execution-result-action-${index}`"
                         :action="action"
                         :ruleTasks="ruleTasks"/>
        </span>
      </div>
    </div>

    <b-row class="mt-3">
      <b-col cols="4" v-for="(item, index) in metricsData" :key="index">
        <div class="rounded p-3 mb-5" :class="item.valueClass">
          <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">{{ item.label }}</span>
          <span class="text-gray-600 fw-bold d-block fs-6">{{ item.valuePretty || item.value }}</span>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="canAccessMetricsDebugView && metricConditionsData" class="mt-3">
      <div>
        <span class="text-gray-800 text-hover-primary fs-6 fw-bolder">Metric Conditions Data:&nbsp;</span>
      </div>
      <div class="metricConditionTree">
        <combine v-for="(node, index) in metricConditionsData"
                 :key="index"
                 :node="node"
                 :ruleTasks="ruleTasks"/>
      </div>


<!--      <vue-json-pretty :data="metricConditionsData"-->
<!--                       :showLength="true"-->
<!--                       :showLine="false"-->
<!--                       :showDoubleQuotes="false"-->
<!--                       :virtual="false"-->
<!--                       :deep="0"-->
<!--                       :deepCollapseChildren="true"-->
<!--                       :selectOnClickNode="false"-->
<!--                       :highlightSelectedNode="false"-->
<!--      />-->
    </b-row>
  </b-modal>
  <!--end::Modal - View Users-->
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import Combine from "@/components/automation/metricConditionTree/condition/Combine.vue";
import MetricAction from "@/views/automation/actionLog/modal/metrics/Action.vue";

export default defineComponent({
  name: "Metrics",

  components: {
    Combine,
    MetricAction,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    executionResult: {
      type: Object,
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters({
      // TODO: update after AuthModule is refactored
      userSettings: 'getUserSettings',
    }),

    canAccessMetricsDebugView(): boolean
    {
      return this?.userSettings?.access_metrics_debug_view ?? false;
    },

    metricConditionsData() {
      return this?.executionResult?.metric_conditions_data ?? null;
    },

    metricsData(): any[] {
      const metricsData = this?.executionResult?.metrics_data;
      if (!metricsData) return [];

      const ruleMetricsRenderConfig = this.executionResult?.execution?.rule?.settings?.render?.metrics;

      let result: any[] = Object.keys(metricsData).map((key) => {
        let metric = metricsData[key];

        if(!metric || typeof metric !== 'object') {
          return null;
        }

        // skip metric if render config is set and does not include it
        if(
          ruleMetricsRenderConfig &&
          Array.isArray(ruleMetricsRenderConfig) &&
          ruleMetricsRenderConfig.length > 0 &&
          !ruleMetricsRenderConfig.includes(metric.name)
        ) {
          return null;
        }

        let valuePretty: null|string|number = null;
        let valueClass: null|string = null;

        if (metric.value === null) {
          valuePretty = 'N/A';
          valueClass = 'bg-light-light';
        } else if (metric.value === true) {
          valuePretty = 'Yes';
          valueClass = 'bg-light-success';
        } else if (metric.value === false) {
          valuePretty = 'No';
          valueClass = 'bg-light-danger';
        }

        return {
          label: this.$t(metric.name),
          value: metric.value,
          valuePretty,
          valueClass
        }
      }).filter((item) => item != null);

      result = result.sort((a, b) => {
        return (a.label < b.label ? -1 : (a.label > b.label ? 1 : 0));
      });

      return result;
    },

    actions() {
      return this.executionResult?.actions;
    },

    tacticName() {
      return this.executionResult?.execution?.rule?.tactic?.name;
    },

    ruleName() {
      return this.executionResult?.execution?.rule?.name;
    },

    ruleTasks(): any[]|null {
      return this.executionResult?.execution?.rule?.tasks ?? null;
    },

    objectType() {
      // return this.action?.object_type ? this.$t(this.action?.object_type) : '';
      return this.executionResult?.object_type_label || '';
    },

    objectId() {
      return this.executionResult?.object_id;
    },

    objectName() {
      return this.executionResult?.object?.name;
    },
  },

  methods: {
    onChange(value) {
      this.$emit('update:modelValue', value);
    },
  }
})
</script>

<style scoped>
.model-footer .btn-secondary {
  display: none;
}
</style>