<template>
  <b-input-group>
    <el-select
      class="form-select-solid"
      placeholder="Select Tactic Rule"
      v-model="selectedTactic"
      :loading="dropdownListIsLoading"
    >
      <el-option label="All" value="all" />
      <el-option-group v-for="dropdownGroup in dropdownOptions"
                       :key="`tactic-dropdown-group-${dropdownGroup.value}`"
                       :label="dropdownGroup.label">
              <el-option v-for="(item, index) in dropdownGroup.rules"
                         :label="item.label"
                         :value="item.value"
                         :key="`tactic-option-${index}`"/>
      </el-option-group>
    </el-select>
  </b-input-group>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { ListItem } from '@/store/modules/automation/dropdown/tactics/state'

export default defineComponent({
  name: "RuleSelect",

  computed: {
    ...mapGetters({
      dropdownList: 'automation/dropdown/tactics/list',
      dropdownListIsLoading: 'automation/dropdown/tactics/listIsFetching',

      filters: 'automation/ruleExecutionResults/listFilters'
    }),

    dropdownOptions(): ListItem[] {
      if(this.dropdownListIsLoading) return [];
      return this.dropdownList.filter((tactic) => {
        return ('rules' in tactic && tactic.rules.length != 0);
      });
    },

    selectedTactic:  {
      get(): string {
        return this.filters.rule ?? 'all';
      },
      set(value) {
        this.setListFilter({
          key: 'rule',
          value: (value !== 'all') ? value : null
        });
      }
    }
  },

  methods: {
    ...mapActions({
      fetchDropdownList: 'automation/dropdown/tactics/fetch',
    }),
    ...mapMutations({
      setListFilter: 'automation/ruleExecutionResults/setListFilter'
    }),
  },

  mounted() {
    this.fetchDropdownList();
  }
});
</script>

<style scoped>

</style>