
import {defineComponent, PropType} from "vue";
import {default as Compare, CompareNode} from "@/components/automation/metricConditionTree/condition/Compare.vue";

export type CombineNode = {
  conditions: (CombineNode|CompareNode)[],
  matched: boolean,
  operator: string,
  type: string,
  rule_task_id ?: null|number,
}

export default defineComponent({
  name: "Combine",

  components: {
    Compare
  },

  props: {
    node: {
      type: Object as PropType<CombineNode>,
      required: true,
    },
    ruleTasks: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      nestedNodesMeta: {
        expanded: true,
      }
    };
  },

  computed: {
    matchClass(): string {
      return this.node.matched ? 'text-success' : 'text-danger'
    },

    ruleTask() {
      if(!this.ruleTasks) return null;

      const ruleTaskId = this.node?.rule_task_id ?? null;
      if(!ruleTaskId) return null;

      return this.ruleTasks.find(item => item.id === ruleTaskId);
    },

    // TODO: implement and use ruleTask.label
    ruleTaskName() {
      return this.ruleTask?.name;
    },

  },

  methods: {
    toggleNestedNodes(event, state:boolean|null = null) {
      if(state === null) state = !this.nestedNodesMeta.expanded;
      this.nestedNodesMeta.expanded = state;
    }
  }

});
