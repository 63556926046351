<template>
  <div>
    <span>
      <template v-if="expressionName">
        <span class="metric-formula me-1">{{expressionName}}</span>
      </template>
      <template v-if="metricName">
          <template v-if="expressionName">
            <span class="me-1">(</span>
            <span class="metric-name me-1">{{metricName}}</span>
            <span class="me-1">)</span>
          </template>
          <span v-else class="metric-name me-1">{{metricName}}</span>
          <span v-if="formula" class="me-1">=</span>
      </template>
      <template v-if="formula">
        <span v-if="exception"
              class="metric-formula metric-formula-exception me-1"
              v-b-tooltip.hover
              :title="exception">
          {{formula}}
        </span>
        <span v-else class="metric-formula me-1">{{formula}}</span>
      </template>
      <span class="me-1">=</span>
      <strong v-b-tooltip.hover :title="valueRaw">{{value}}</strong>
    </span>
  </div>
  <div v-if="multiplier !== null && multiplier !== 0" class="ps-3">
    <span class="metric-formula me-1">multiplier</span>
    <span v-if="formula" class="me-1">=</span>
    <strong>{{multiplier}}</strong>
  </div>

  <div v-if="nestedExpressions" class="ps-3">
    <calculated v-for="(expression, expressionName) in nestedExpressions"
                :key="expressionName"
                :expression-name="expressionName"
                :expression="expression"/>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "Calculated",
  props: {
    expression: {
      type: [Number, String, Object],
      required: true,
    },
    expressionName: {
      type: String,
    },
  },

  components: {

  },

  computed: {
    metricName(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      let name = this.expression.metric?.name;
      if(!name) return null;
      return this.$t(name);
    },
    valueRaw(): string {
      let value = (!this.expression || typeof this.expression !== 'object')
        ? this.expression
        : this.expression.value;
      return String(value);
    },
    value(): string {
      let value = (!this.expression || typeof this.expression !== 'object')
                    ? this.expression
                    : this.expression.value;

      if(typeof value === 'number' && !Number.isInteger(value)) {
        value = value.toFixed(2).replace(new RegExp("[0]$"), '');
      }
      return String(value);
    },
    formula(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric.formula ?? null;
    },
    exception(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric.exception ?? null;
    },
    nestedExpressions(): null|Record<string, any> {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric?.nestedMetrics ?? null;
    },
    multiplier(): null|number {
      if(!this.expression || typeof this.expression !== 'object') return null;
      const metricAttributes = this.expression.metric.attributes;
      if(!metricAttributes || Array.isArray(metricAttributes)) return null;
      return metricAttributes?.multiplier ?? null;
    }

  }
});
</script>

<style scoped>

</style>