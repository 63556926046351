
import {defineComponent} from "vue";

export default defineComponent({
  name: "ObjectName",

  props: {
    row: {
      type: Object,
    }
  },

  computed: {
    objectName() {
      return this.row?.object?.name;
    }
  }

});
