
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import TimeAgo from "@/components/general/TimeAgo.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ColumnObjectName from './actionLog/columns/ObjectName.vue';
import ColumnRuleName from './actionLog/columns/RuleName.vue';
import Filters from './actionLog/Filters.vue';
import ModalMetrics from './actionLog/modal/Metrics.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';

export default defineComponent({
  name: "AutomationActionLog",
  components: {
    Filters,
    KtDatatable,
    TimeAgo,
    ColumnObjectName,
    ColumnRuleName,
    ModalMetrics,
    InvalidShop
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Action Logs");
    });
  },
  data() {
    return {
      modals: {
        metrics: {
          isOpen: false,
          executionResult: null,
        },
      },

      datagrid: {
        columns: [
          {
            name: 'Time',
            columnKey: 'timeAgo',
            key: 'created_at',
          },
          {
            name: 'Rule',
            columnKey: 'ruleName',
          },
          {
            name: 'Type',
            columnKey: 'objectType',
            key: 'object_type',
          },
          // {
          //   name: 'Action',
          //   key: 'action',
          // },
          {
            name: 'ID',
            columnKey: 'objectId',
            key: 'object_id',
          },
          {
            name: 'Name',
            columnKey: 'objectName',
          },
        ],
      }
    };
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
      list: 'automation/ruleExecutionResults/list',
      listIsLoading: 'automation/ruleExecutionResults/listIsFetching',
      listPaginationTotalRecordsCount: 'automation/ruleExecutionResults/listPaginationTotalRecordsCount',
      listPaginationCurrentPage: 'automation/ruleExecutionResults/listPaginationCurrentPage',
      listPaginationItemsPerPage: 'automation/ruleExecutionResults/listPaginationItemsPerPage',
      listSortColumnName: 'automation/ruleExecutionResults/listSortColumnName',
      listSortOrder: 'automation/ruleExecutionResults/listSortOrder',
    }),
  },

  mounted() {
    if(!this.selectedShopifyShop) return;
    this.fetchList();
  },

  watch: {
    'selectedShopifyShop.id'() {
      if(!this.selectedShopifyShop.facebookAdAccounts || !this.selectedShopifyShop.facebookAdAccounts.length) return;
      this.fetchList();
    }
  },
  
  methods: {
    ...mapActions({
      fetchList: 'automation/ruleExecutionResults/fetch'
    }),
    ...mapMutations({
      setListPaginationCurrentPage: 'automation/ruleExecutionResults/setListPaginationCurrentPage',
      setListPaginationTotalRecordsCount: 'automation/ruleExecutionResults/setListPaginationTotalRecordsCount',
      setListPaginationItemsPerPage: 'automation/ruleExecutionResults/setListPaginationItemsPerPage',
      setListSortColumnName: 'automation/ruleExecutionResults/setListSortColumnName',
      setListSortOrder: 'automation/ruleExecutionResults/setListSortOrder',
    }),

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.setListPaginationCurrentPage(currentPage);
      this.fetchList();
    },

    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.setListPaginationItemsPerPage(itemsPerPage);
      this.fetchList();
    },

    onDatagridSortingChange(sort) {
      this.setListSortOrder(sort.order);
      this.setListSortColumnName(sort.columnName);
      this.fetchList();
    },

    onRowClick(row) {
      this.modals.metrics.executionResult = row;
      this.showMetrics();
    },

    showMetrics() {
      this.modals.metrics.isOpen = true;
    }

  },
});
