
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import { ListItem } from '@/store/modules/automation/dropdown/tactics/state'

export default defineComponent({
  name: "TacticSelect",

  computed: {
    ...mapGetters({
      dropdownList: 'automation/dropdown/tactics/list',
      dropdownListIsLoading: 'automation/dropdown/tactics/listIsFetching',

      filters: 'automation/ruleExecutionResults/listFilters'
    }),

    dropdownOptions(): ListItem[] {
      if(this.dropdownListIsLoading) return [];
      return [{
        label: 'All',
        value: 'all'
      }].concat(this.dropdownList);
    },

    selectedTactic:  {
      get(): string {
        return this.filters.tactic ?? 'all';
      },
      set(value) {
        this.setListFilter({
          key: 'tactic',
          value: (value !== 'all') ? value : null
        });
      }
    }
  },

  methods: {
    ...mapActions({
      fetchDropdownList: 'automation/dropdown/tactics/fetch',
    }),
    ...mapMutations({
      setListFilter: 'automation/ruleExecutionResults/setListFilter'
    }),
  },

  mounted() {
    this.fetchDropdownList();
  }
});
