
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import {defineComponent} from "vue";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export default defineComponent({
  name: "TimeAgo",
  props: {
    time: {
      type: [Date, String],
      default: null,
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm',
    },
    localize: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    timeAgo() {
      if(!this.time) return null;
      if(!this.localize) {
        return dayjs(this.time).fromNow()
      }
      return dayjs(this.time).tz(dayjs.tz.guess()).fromNow();
    },
    formattedDate() {
      if(!this.time) return null;
      if(!this.localize) {
        return dayjs(this.time).format(this.format)
      }
      return dayjs(this.time).tz(dayjs.tz.guess()).format(this.format);
    }
  }
})
