<template>
  <div class="row gy-5 g-xl-8">
    <template v-if="selectedShopifyShop && selectedShopifyShop.facebookAdAccounts && selectedShopifyShop.facebookAdAccounts.length">
      <b-card>
        <modal-metrics v-model="modals.metrics.isOpen"
                       :execution-result="modals.metrics.executionResult" />

        <filters />
        <kt-datatable :current-page="listPaginationCurrentPage"
                      :loading="listIsLoading"
                      :rows-per-page="listPaginationItemsPerPage"
                      :total="listPaginationTotalRecordsCount"
                      :table-data="list"
                      :table-header="datagrid.columns"
                      :sort-column-name="listSortColumnName"
                      :sort-order="listSortOrder"
                      :row-class="'cursor-pointer'"
                      v-on:current-change="onDatagridPaginationCurrentPageChange"
                      v-on:items-per-page-change="onDatagridPaginationItemsPerPageChange"
                      v-on:sort="onDatagridSortingChange"
                      v-on:row-click="onRowClick"
        >

          <template v-slot:cell-timeAgo="{ row: data }">
            <time-ago :time="data.created_at" />
            <i v-if="!data.is_executable" class="fas fa-exclamation-triangle text-gray-600 ms-2 fs-7" title="Unperformed action" />
            <i v-if="data.is_emulated" class="fas fa-server text-warning ms-2 fs-7" title="Emulated" />
          </template>

          <!--        <template v-slot:cell-tactic="{ row: data }">-->
          <!--          <column-tactic-name :row="data" />-->
          <!--        </template>-->
          <template v-slot:cell-ruleName="{ row: data }">
            <column-rule-name :row="data" />
          </template>
          <template v-slot:cell-objectType="{ row: data }">
            {{ $t(data.object_type_label) }}
          </template>
<!--          <template v-slot:cell-action="{ row: data }">-->
<!--            <div class="d-flex align-items-center">-->
<!--              <span>{{ $t(data.action_label) }}</span>-->
<!--            </div>-->
<!--          </template>-->
          <template v-slot:cell-objectId="{ row: data }">
            {{ data.object_id }}
          </template>
          <template v-slot:cell-objectName="{ row: data }">
            <column-object-name :row="data" />
          </template>

        </kt-datatable>

      </b-card>
    </template>
    <template v-else>

      <invalid-shop />

    </template>
  </div>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import TimeAgo from "@/components/general/TimeAgo.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ColumnObjectName from './actionLog/columns/ObjectName.vue';
import ColumnRuleName from './actionLog/columns/RuleName.vue';
import Filters from './actionLog/Filters.vue';
import ModalMetrics from './actionLog/modal/Metrics.vue';
import InvalidShop from '@/components/shopify/InvalidShop.vue';

export default defineComponent({
  name: "AutomationActionLog",
  components: {
    Filters,
    KtDatatable,
    TimeAgo,
    ColumnObjectName,
    ColumnRuleName,
    ModalMetrics,
    InvalidShop
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Action Logs");
    });
  },
  data() {
    return {
      modals: {
        metrics: {
          isOpen: false,
          executionResult: null,
        },
      },

      datagrid: {
        columns: [
          {
            name: 'Time',
            columnKey: 'timeAgo',
            key: 'created_at',
          },
          {
            name: 'Rule',
            columnKey: 'ruleName',
          },
          {
            name: 'Type',
            columnKey: 'objectType',
            key: 'object_type',
          },
          // {
          //   name: 'Action',
          //   key: 'action',
          // },
          {
            name: 'ID',
            columnKey: 'objectId',
            key: 'object_id',
          },
          {
            name: 'Name',
            columnKey: 'objectName',
          },
        ],
      }
    };
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
      list: 'automation/ruleExecutionResults/list',
      listIsLoading: 'automation/ruleExecutionResults/listIsFetching',
      listPaginationTotalRecordsCount: 'automation/ruleExecutionResults/listPaginationTotalRecordsCount',
      listPaginationCurrentPage: 'automation/ruleExecutionResults/listPaginationCurrentPage',
      listPaginationItemsPerPage: 'automation/ruleExecutionResults/listPaginationItemsPerPage',
      listSortColumnName: 'automation/ruleExecutionResults/listSortColumnName',
      listSortOrder: 'automation/ruleExecutionResults/listSortOrder',
    }),
  },

  mounted() {
    if(!this.selectedShopifyShop) return;
    this.fetchList();
  },

  watch: {
    'selectedShopifyShop.id'() {
      if(!this.selectedShopifyShop.facebookAdAccounts || !this.selectedShopifyShop.facebookAdAccounts.length) return;
      this.fetchList();
    }
  },
  
  methods: {
    ...mapActions({
      fetchList: 'automation/ruleExecutionResults/fetch'
    }),
    ...mapMutations({
      setListPaginationCurrentPage: 'automation/ruleExecutionResults/setListPaginationCurrentPage',
      setListPaginationTotalRecordsCount: 'automation/ruleExecutionResults/setListPaginationTotalRecordsCount',
      setListPaginationItemsPerPage: 'automation/ruleExecutionResults/setListPaginationItemsPerPage',
      setListSortColumnName: 'automation/ruleExecutionResults/setListSortColumnName',
      setListSortOrder: 'automation/ruleExecutionResults/setListSortOrder',
    }),

    onDatagridPaginationCurrentPageChange(currentPage) {
      this.setListPaginationCurrentPage(currentPage);
      this.fetchList();
    },

    onDatagridPaginationItemsPerPageChange(itemsPerPage) {
      this.setListPaginationItemsPerPage(itemsPerPage);
      this.fetchList();
    },

    onDatagridSortingChange(sort) {
      this.setListSortOrder(sort.order);
      this.setListSortColumnName(sort.columnName);
      this.fetchList();
    },

    onRowClick(row) {
      this.modals.metrics.executionResult = row;
      this.showMetrics();
    },

    showMetrics() {
      this.modals.metrics.isOpen = true;
    }

  },
});
</script>