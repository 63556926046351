<template>
  <input
      class="form-check-input h-15px w-15px align-middle"
      type="checkbox"
      v-model="showNonExecutable"
      checked="checked"
    />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "NonExecutableToggle",

  computed: {
    ...mapGetters({
      filters: 'automation/ruleExecutionResults/listFilters'
    }),

    showNonExecutable:  {
      get(): boolean {
        return this.filters.status === 'all';
      },
      set(value) {
        return this.setListFilter({
          key: 'status',
          value: value ? 'all' : 'only-executable'
        });
      }
    }
  },

  methods: {
    ...mapMutations({
      setListFilter: 'automation/ruleExecutionResults/setListFilter'
    }),
  },

});
</script>

<style scoped>

</style>