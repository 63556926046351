
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "Calculated",
  props: {
    expression: {
      type: [Number, String, Object],
      required: true,
    },
    expressionName: {
      type: String,
    },
  },

  components: {

  },

  computed: {
    metricName(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      let name = this.expression.metric?.name;
      if(!name) return null;
      return this.$t(name);
    },
    valueRaw(): string {
      let value = (!this.expression || typeof this.expression !== 'object')
        ? this.expression
        : this.expression.value;
      return String(value);
    },
    value(): string {
      let value = (!this.expression || typeof this.expression !== 'object')
                    ? this.expression
                    : this.expression.value;

      if(typeof value === 'number' && !Number.isInteger(value)) {
        value = value.toFixed(2).replace(new RegExp("[0]$"), '');
      }
      return String(value);
    },
    formula(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric.formula ?? null;
    },
    exception(): null|string {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric.exception ?? null;
    },
    nestedExpressions(): null|Record<string, any> {
      if(!this.expression || typeof this.expression !== 'object') return null;
      return this.expression.metric?.nestedMetrics ?? null;
    },
    multiplier(): null|number {
      if(!this.expression || typeof this.expression !== 'object') return null;
      const metricAttributes = this.expression.metric.attributes;
      if(!metricAttributes || Array.isArray(metricAttributes)) return null;
      return metricAttributes?.multiplier ?? null;
    }

  }
});
