<template>
  <b-input-group>
    <b-form-input v-model="search"
                  @keydown="onKeyDown"
                  placeholder="Search..."/>
  </b-input-group>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: "Search",

  computed: {
    ...mapGetters({
      filters: 'automation/ruleExecutionResults/listFilters'
    }),

    search:  {
      get(): null|string {
        return this.filters?.search;
      },
      set(value) {
        this.setListFilter({
          key: 'search',
          value: value
        });
      }
    }
  },

  methods: {
    ...mapMutations({
      setListFilter: 'automation/ruleExecutionResults/setListFilter'
    }),
    ...mapActions({
      fetchList: 'automation/ruleExecutionResults/fetch'
    }),

    onKeyDown(event) {
      if(event.keyCode !== 13) return;
      this.fetchList();
    }
  }
});
</script>

<style scoped>

</style>