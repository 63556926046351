
import {defineComponent, PropType} from "vue";

export type ExpressionNode = {
  value: any,
  valueIdentifier: string,
  metric: {
    attributes: Record<string, any>,
    type: string,
    name: string,
    objectType?: string,
    formula?: string,
    nestedMetrics: Record<string, any>
  },
}

export default defineComponent({
  name: "Expression",
  props: {
    expression: {
      type: Object as PropType<ExpressionNode>,
      required: true,
    },
  },

  components: {

  },

  computed: {
    metricName(): null|string {
      let name = this.expression?.metric?.name;
      if(!name) return null;
      return this.$t(name);
    },
    valueRaw(): string {
      return String(this.expression.value);
    },
    value(): string {
      let value = this.expression.value;
      if(typeof value === 'number' && !Number.isInteger(value)) {
        value = value.toFixed(2).replace(new RegExp("[0]$"), '');
      }
      return String(value);
    },

  }
});
